const ResetIcon = () => {
    const svgIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
    <path d="M9 4.25V1.25L5.25 5L9 8.75V5.75C11.4825 5.75 13.5 7.7675 13.5 10.25C13.5 12.7325 11.4825 14.75 9 14.75C6.5175 14.75 4.5 12.7325 4.5 10.25H3C3 13.565 5.685 16.25 9 16.25C12.315 16.25 15 13.565 15 10.25C15 6.935 12.315 4.25 9 4.25Z" fill="#FF453A"/>
  </svg>
        `;
  
    return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
  };
  
  export default ResetIcon;
  

