import { getCoreRowModel, getPaginationRowModel } from "@tanstack/react-table";

export const getTableOptions = (data: any, columns: any) => ({
    data: data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  initialState: {
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
  },

});
