import Heading from "../common/heading/Heading";
import Text from "../common/text/Text";
import Wrapper from "../common/wrapper/Wrapper";
import { CardProps } from "./SalesReportCard.type";

const SalesReportCard: React.FC<CardProps> = ({
  title,
  value,
  title2,
  value2,
}) => {
  return (
    <Wrapper
      onClick={() => {}}
      classes="bg-white rounded-lg flex flex-col px-4 py-4 items-center justify-evenly shadow-custom3 w-full "
    >
      {title2 ? (
        <div className="w-full flex justify-between">
          <span className="flex flex-col items-center text-center text-2xl">
            <Text
              align=""
              children={title}
              classes="text-slate-500 "
              color=""
              size="text-2xl"
            />
            <Heading align="" children={value} classes="" color="" size="h2" />
          </span>
          <span className="flex flex-col items-center text-2xl">
            <Text
              align=""
              children={title2}
              classes="text-slate-500 "
              color=""
              size="text-2xl"
            />
            <Heading align="" children={value2} classes="" color="" size="h2" />
          </span>
        </div>
      ) : (
        <>
          <Heading align="" children={value} classes="" color="text-xl md:text-3xl" size="h1" />
          <Text
            align=""
            children={title}
            classes="text-custom-textGraySec pt-3"
            color=""
            size="text-base md:text-xl"
          />
        </>
      )}
    </Wrapper>
  );
};

export default SalesReportCard;
