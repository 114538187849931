import "react-datepicker/dist/react-datepicker.css";
import Select from "../common/select/Select";
import { useEffect, useState } from "react";
import { SalesFiltersProps } from "./SalesFilter.type";
import { DatePicker } from "antd";
import dayjs from 'dayjs';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from "react-i18next";
import "./SalesFilter.css"
import Heading from "../common/heading/Heading";
import Text from "../common/text/Text";
import Wrapper from "../common/wrapper/Wrapper";

const SalesFilters: React.FC<SalesFiltersProps> = ({
  sendMenuIdToParent,
  timeSinc,
  dateSinc,
}) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate());


  const UserMenus = localStorage.getItem("UserMenus");

  const UserMenusParsed = UserMenus ? JSON.parse(UserMenus) : null;

  const options = UserMenusParsed.map((location: any) => {
    return { value: location.IdMenu, label: location.MenuName };
  });
  const [selectedLocation, setSelectedLocation] = useState(options.length  ? options[0].value : null);
  const { t } = useTranslation();
  const lsMenuId = localStorage.getItem("menuId");
  const parsedLsMenuId = lsMenuId && JSON.parse(lsMenuId);
  const option = parsedLsMenuId ? { value: parsedLsMenuId.IdMenu, label: parsedLsMenuId.MenuName } : null;


  const handleSelectChange = (selectedOption: any) => {
    const selectedLocationData = UserMenusParsed.find(
      (location: any) => location.IdMenu === selectedOption
    );
    const selectedMenuArray = { IdMenu: selectedLocationData.IdMenu, MenuName: selectedLocationData.MenuName }
    localStorage.setItem("menuId", JSON.stringify(selectedMenuArray))
    localStorage.setItem("MI", JSON.stringify(selectedMenuArray.IdMenu))

    if(selectedLocationData) {
      localStorage.setItem("longitude", selectedLocationData.Longitude);
      localStorage.setItem("latitude", selectedLocationData.Latitude);
      localStorage.setItem("fullAddress", selectedLocationData.FullAddress)
    }
    window.location.reload();

  };

  useEffect(() => {
    sendMenuIdToParent(selectedLocation);
  }, [selectedLocation]);

  return (
    <Wrapper onClick={() => {}} classes="pt-10 md:pt-[72px] flex flex-col items-center justify-center pb-4 fixed left-0 md:left-[20%] right-0 bg-[#fafcfff2]  z-10">
            <div className="w-[95%] mx-auto flex flex-col md:flex-row items-end">

          <div className="w-full">
            <Heading
              align="left"
              children={t("sales.title")}
              classes="pt-4 md:pt-2 min-w-full text-[22px] md:text-4xl"
              color=""
              size="h1"
            />
            <Text
              align=""
              children={t("sales.subTitle")}
              classes="text-[15px] md:text-base font-thin"
              color="text-custom-textGraySec"
              size=""
            />
          </div> 
          <div className="w-full md:hidden">
        <Select
          classes="md:hidden flex justify-center rounded-lg border border-custom-bgBlue bg-white md:w-1/3 relative mt-3 py-[7px]"
          options={options}
          onChange={handleSelectChange}
          defaultValue={option}
        />
      </div>
      <div className="flex flex-col h-fit w-full md:w-8/12 mt-4 md:pb-0">
        <p className="text-sm md:text-base mb-1">{t("sales.dateAndTime")}</p>

      <fieldset>
        {dateSinc && timeSinc &&
        <DatePicker
        readOnly
        value={dayjs(`'${dateSinc} ${timeSinc}'`, 'DD/MM/YYYY hh:mm:ss A')}
        format="DD/MM/YYYY hh:mm:ss "
        datatype="dd/mm/yyyy"
        // defaultValue={dayjs(`'${dateSinc} ${timeSinc}'`, 'DD/MM/YYYY hh:mm:ss A')}
        showHour={true}
        inputReadOnly 
        showNow={false}
        disabled
        suffixIcon={false}
        className="bg-custom-bgBlue py-2 border-none min-w-full custom-datepicker rounded-lg"
        />    
      }
      </fieldset>
      </div>
      </div>

    </Wrapper>
  );
};

export default SalesFilters;
