import React, { useEffect, useState } from "react";
import Wrapper from "../common/wrapper/Wrapper";

import { useTranslation } from "react-i18next";
import Weather1Icon from "../icons/Weather1Icon";
import Weather2Icon from "../icons/Weather2Icon";
import Weather3Icon from "../icons/Weather3Icon";
import Weather4Icon from "../icons/Weather4Icon";
import Weather5Icon from "../icons/Weather5Icon";
import Weather6Icon from "../icons/Weather6Icon";


const Weather: React.FC = () => {
    const [weatherData, setWeatherData] = useState<any>(null);
    const [location, setLocation] = useState<{ city: string, state: string }>({ city: '', state: '' });
    const [loading, setLoading] = useState<boolean>(true);
    const { t } = useTranslation();
    const latitude = localStorage.getItem("latitude");
    const longitude = localStorage.getItem("longitude");
    const fullAddress = localStorage.getItem("fullAddress")

 
  // console.log(longitude, latitude, fullAddress)å
    useEffect(() => {
      const fetchWeatherData = async (latitude: number, longitude: number) => {
        try {
              const apiKey = "WYABQSX68YEN5AGA27V5YDF3A"; // Replace with your Visual Crossing Weather API key
              const apiUrl = `https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/${latitude},${longitude}?key=${apiKey}`;
              
              const response = await fetch(apiUrl);
              const data = await response.json();
              setWeatherData(data);
              setLoading(false);       
        } catch (error) {
          console.error("Error fetching weather data:", error);
        }
      };
  
      const fetchLocationData = async (latitude: number, longitude: number) => {
        try {
          if(latitude && longitude){


            const apiKey = "46414403c29f4a1285729775cde13959"; // Replace with your OpenCage API key
            const apiUrl = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`;
            
            const response = await fetch(apiUrl);
            const data = await response.json();
            const { city, state } = data.results[0].components;
            setLocation({ city, state });
          }else{
            console.log("no coordinates")
          }
        } catch (error) {
          console.error("Error fetching location data:", error);
        }
      };
  
      const fetchData = async () => {
        try {
                    if (longitude && latitude && fullAddress){
                      await fetchWeatherData(parseFloat(latitude), parseFloat(longitude));
                      await fetchLocationData(parseFloat(latitude), parseFloat(longitude));
                    }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
  
      fetchData();
    }, [longitude, latitude]);

  const fToC = (value: any): string => {
    // Convert Fahrenheit to Celsius
    const celsius = (value - 32) * 5/9;
  
    // Round to the nearest integer
    const roundedCelsius = Math.round(celsius);
  
    // Return the integer value with the Celsius symbol
    return `${roundedCelsius}°`;
  }

  return (
    <Wrapper onClick={() => {}} classes="bg-white rounded-lg px-4 py-2 md:px-12 md:py-7 min-w-full flex flex-col shadow-custom3 mb-4 h-full">
      {weatherData ? (
        <div>
          <h2 className="mb-8 text-2xl font-semibold">{t("weather.title")}</h2>
          <div className="relative">
            {(() => {
              const { icon } = weatherData?.days[0];
              if (icon === "clear-day") {
                return <span className="absolute right-4 md:right-20 -top-8"><Weather6Icon /></span>;
              } else if (icon === "clear-night" || icon === "partly-cloudy-night") {
                return <span className="absolute right-4 -top-8"><Weather2Icon /></span>;
              } else if (icon === "cloudy") {
                return <span className="absolute right-4 md:right-20 -top-8"><Weather3Icon /></span>;
              } else if (icon === "thunderstorm") {
                return <span className="absolute right-4 md:right-20 -top-8"><Weather4Icon /></span>;
              } else if (icon === "rain") {
                return <span className="absolute right-4 md:right-20 -top-8"><Weather5Icon /></span>;
              } else if (icon === "partly-cloudy-day") {
                return <span className="absolute right-4 md:right-20 -top-8"><Weather1Icon /></span>;
              } else {
                return null;
              }
            })()}
  
            <img src="/Rectangle 1.png" alt="" />
            <span className="absolute top-1/4 left-4 text-white text-4xl">{fToC(weatherData?.days[0].temp)}<br></br></span>
            <span style={{color: "#EBEBF599"}} className="absolute top-1/2 left-4 ">H:{fToC(weatherData?.days[0].tempmax)} L:{fToC(weatherData?.days[0].tempmin)}<br/> <p className="text-white">{fullAddress}</p></span>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </Wrapper>
  );
  
};

export default Weather;
