// useSoldProductsFilters.ts
import { useState } from 'react';

interface TaxOption {
    id: number;
    value: any;
  }

export function useSoldProductsFilters(taxTypeOptions: TaxOption[]) {
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<any>(["1"]);
  const [selectedTax, setSelectedTax] = useState<any>(["0"]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<number[]>([0]);
  const [selectedMainCategory, setSelectedMainCategory] = useState<number[]>([0]);
  const [selectedProducts, setSelectedProducts] = useState<any>(["0"]);
  const [selectedTaxParent, setSelectedTaxParent] = useState<any>();


  const handleTimePeriodChange = (period: string) => {
    setSelectedTimePeriod(period);
  };

  const handleTaxChange = (tax: any) => {
    setSelectedTax(tax)
  }

  const handleSubCategoryChange = (selectedOption: any) => {
    setSelectedSubCategory(selectedOption);
  }

  const handleMainCategoryChange = (selectedOption: any) => {
    if (selectedOption.some((option: any) => option.value === 0)) {
      setSelectedMainCategory([0]);
    } else {
      setSelectedMainCategory(selectedOption);
    }
  };

  const handleProductsChange = (product: any) => {
    if (product.some((option: any) => option.value === 0)) {
      setSelectedProducts([0]);
    } else {
      setSelectedProducts(product)
    }
    setSelectedProducts(product)
  }

  const handleParentTaxChange = (parentTax: number[]) => {
    const matchedOptions = taxTypeOptions.filter((option: TaxOption) => parentTax.includes(option.id));

    if (matchedOptions.length > 0) {
      const matchedValues = matchedOptions.map((option: TaxOption) => option.value);
      setSelectedTaxParent(matchedValues);
      setSelectedTax(matchedValues);
    }
  };
  

  return {
    selectedTimePeriod,
    selectedTax,
    selectedSubCategory,
    selectedMainCategory,
    selectedProducts,
    selectedTaxParent,
    handleTimePeriodChange,
    handleTaxChange,
    handleSubCategoryChange,
    handleMainCategoryChange,
    handleProductsChange,
    handleParentTaxChange,
    setSelectedTax
  };
}
