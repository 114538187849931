import { useTranslation } from "react-i18next";
import Wrapper from "../common/wrapper/Wrapper";
import ZReportRow from "../zReportRow/ZReportRow";

interface Props {
    ordersTotals: any;
}


const Totals: React.FC<Props> = ({ordersTotals}) => {
    const { t } = useTranslation();

  return (
    <Wrapper onClick={() => {}} classes="h-fit w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg">
        
    <ZReportRow title={t("zreport.tables")} value={ordersTotals[0]?.OrdersCount}/>
    <ZReportRow title={t("zreport.avgPB")} value={ordersTotals[0]?.OrdersAvg.toFixed(2)}/>
    <ZReportRow title={t("zreport.person")} value={ordersTotals[0]?.Persons}/>
    <ZReportRow title={t("zreport.avgPP")} value={ordersTotals[0]?.PersonsAvg.toFixed(2)}/>
    <ZReportRow title={t("zreport.total")} value={ordersTotals[0]?.SubTotal.toFixed(2)} color="bg-custom-bgPink" classes="mt-2"/>
  </Wrapper>
  );
};

export default Totals;
