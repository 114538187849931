import Wrapper from "../common/wrapper/Wrapper";
import React, { useContext, useEffect, useState } from "react";
import i18n from "../../i18n";
import { LoadingLiveDataContext } from "../../context/isLoadingLiveDataContext";
import useAxios from "../../hooks/useAxios";
import { API_ROUTES } from "../../config";
import { Corrections, Details, Discount, OnTheHouse, Payments, PeriodProps, SumsByStatus, TaxInterface, TaxParentGroup } from "./PeriodReport.type";
import PeriodReportTable from "../periodReportTable/PeriodReportTable";
import ArrowDownIcon from "../icons/ArrowDownIcon";
import { useTranslation } from "react-i18next";
import ZReportRow from "../zReportRow/ZReportRow";
import { v4 as uuidv4 } from 'uuid';
import ArrowUpIcon from "../icons/ArrowUpIcon";
import ScrollToTopButton from "../common/scrollToTopButton/ScrolToTopButton";
import { getProductsTableHeaders } from "./PeriodReportTableConfig";
import { getTableOptions } from "./PeriodReportTableOptions";
import ExportFilterActions from "../common/reportAction/ExportFilterActions";
import TaxParentGroupComponent from "../zReports/TaxParentGroupComponent";
import ExcludedVatComponent from "../zReports/ExcludedVatComponent";
import TaxComponent from "../zReports/TaxComponent";
import TurnoverComponent from "../zReports/TurnoverComponent";
import DailyTotalsComponent from "../zReports/DailyTotalsComponent";
import PeriodReportFilter from "../periodReportFilters/PeriodReportFilters";

const PeriodReports: React.FC<PeriodProps> = () => {
  const { setIsLoadingLiveData } = useContext(LoadingLiveDataContext);
  const { fetchData } = useAxios();
  const token = localStorage.getItem("token");
  const [details, setDetails] = useState<Details[]>([]);
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [taxExcludeVat, setTaxExcludeVat] = useState<TaxInterface[]>([]);
  const [tax, setTax] = useState<TaxInterface[]>([]);
  const [turnover, setTurnover] = useState<TaxInterface[]>([]);
  const [payments, setPayments] = useState<Payments[]>([]);
  const [onTheHouse, setOnTheHouse] = useState<OnTheHouse[]>([])
  const [corrections, setCorrections] = useState<Corrections[]>([])
  const [outOfTurnover, setOutOfTurnover] = useState<any>([])
  const [totalAmount, setTotalAmount] = useState<any>([])
  const [sumsByStatus, setSumsByStatus] = useState<SumsByStatus[]>([]);
  const [taxParentGroupDetails, setTaxParentGroupDetails] = useState<TaxParentGroup[]>([]);
  const {t} = useTranslation();
  const [openSection, setOpenSection] = useState<string | null>(null);
  const isTaxParentGroup = JSON.parse(localStorage.getItem("toggle_1") || "false");
  const [fileUrl, setFileUrl] = useState<null | string>(null);
  const [exportType, setExportType] = useState<string>("0");
  const [dateFrom, setDateFrom] = useState<any>();
  const [dateTo, setDateTo] = useState<any>();

  const daysTH = getProductsTableHeaders(t); 
  const options = getTableOptions(details, daysTH);
  const menuId = localStorage.getItem("MI")

  const fetchReportData = async () => { 
    if (menuId !== "" && dateFrom !== "" && dateTo !== "") {
      const requestBody = {
        Language: i18n.language,
        IdMenu: menuId,
        TargetDate: dateFrom,
        TargetDateTo: dateTo,
        ExportType: exportType
      };
      setIsLoadingLiveData(true);

      try {
        const apiResponse = await fetchData(API_ROUTES.getPeriodReport, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: requestBody,
        });

        setDetails(apiResponse.data.details);
        setDiscounts(apiResponse.data.discounts);
        setTaxExcludeVat(apiResponse.data.alltaxes.taxExclVAT);
        setTax(apiResponse.data.alltaxes.taxVAT);
        setTurnover(apiResponse.data.alltaxes.turnover);
        setPayments(apiResponse.data.paymentTypes);
        setOnTheHouse(apiResponse.data.fromHouse);
        setCorrections(apiResponse.data.corrections);
        setOutOfTurnover(apiResponse.data.OutOfTurnover);
        setTotalAmount(apiResponse.data.totalAmount);
        setTaxParentGroupDetails(groupByTaxType(apiResponse.data.TaxParentGroupDetails));
        setFileUrl(apiResponse.data.DocumentUrl && apiResponse.data.DocumentUrl.FileName);
      } catch (error) {
        setDetails([]);
        setDiscounts([]);
        setTaxExcludeVat([]);
        setTax([]);
        setTurnover([]);
        setPayments([]);
        setOnTheHouse([]);
        setCorrections([]);
        setOutOfTurnover([]);
        setTotalAmount([]);
        setTaxParentGroupDetails([]);
        console.error(error)
      } finally {
        setIsLoadingLiveData(false);
      }
    }
  };

  const toggleSection = (section: string) => {
    setOpenSection(openSection === section ? null : section);
  };
  const groupByTaxType = (details: any) => {
    return details.reduce((groups: any, item: any) => {
      if (!groups[item.TaxType]) {
        groups[item.TaxType] = [];
      }
      groups[item.TaxType].push(item);
      return groups;
    }, []);
  };

  useEffect(() => {
    const sums: { [key: string]: { status: string; quantity: number } } = {};
  
    onTheHouse.forEach((order: any) => {
      const { Status, Quantity } = order;
  
      // Ensure that both Status and Quantity exist
      if (Status && Quantity !== undefined) {
        if (!sums[Status]) {
          sums[Status] = { status: Status, quantity: 0 };
        }
        sums[Status].quantity += Quantity;
      } else {
        console.warn('Order is missing necessary properties:', order);
      }
    });
  
    const mappedSums = Object.keys(sums).map((status) => ({
      status,
      quantity: sums[status].quantity,
    }));
  
    setSumsByStatus(mappedSums);
  }, [onTheHouse]);

  const handleExportChange = (exportType: string) => {
    setExportType(exportType[0])
  }
const handleFromDate = (date: any) => {
  setDateFrom(date)
}
const handleToDate = (date: any) => {
  setDateTo(date)
}
  return (
    <Wrapper onClick={() => {}} classes="w-[95%] mx-auto pt-2 md:pt-4 pb-16 min-h-screen">
  
        <PeriodReportFilter sendFromDateToParent={handleFromDate} sendToDateToParent={handleToDate} exportType={exportType} fileUrl={fileUrl} getDataBe={fetchReportData} handleExportChange={handleExportChange}/>
        <div className="relative pt-[27rem] md:pt-[22rem]" style={{ maxHeight: "95dvh", overflowY: "auto" }}>

      {details.length > 0 ? (<><div onClick={() => toggleSection("days")} className={`${openSection === "days" ? "mb-4" : "mb-4"} flex justify-between items-center px-2 md:px-4 rounded-lg border border-gray-200 w-full py-2 md:py-4 bg-white shadow-custom3 `}>{t("periodReport.days")} {openSection == "days" ? (<ArrowUpIcon/>) : (<ArrowDownIcon />)} </div>
            {openSection == "days" ? <div className="mb-4">{details.length > 0 && <PeriodReportTable options={options}/>}</div> : <></>}
            
            <div className="md:hidden">
              
            {taxExcludeVat.length > 0 ? ( 
              <>
            <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.excludedVAT")}
            </p>
              <ExcludedVatComponent taxExcludeVat={taxExcludeVat} />
              </>
          ) : (<></>)}

         

            {tax.length > 0 ? (  
              <>
            <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.tax")}
            </p>
              <TaxComponent tax={tax} />
              </>
                    
          ) : (<></>)}


            {turnover.length > 0 ? ( 
              <>
                          <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.turnover")}
            </p>
                          <TurnoverComponent turnover={turnover}/>
              </>  
     
          ) : (<></>)}

{isTaxParentGroup && taxParentGroupDetails.length > 0 ? 
            <>
            <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.parentTaxGroup")}
            </p>
            <TaxParentGroupComponent taxParentGroupDetails={taxParentGroupDetails}/>
            </>
             : <></>}
              {payments.length > 0 &&  <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.payment")}
            </p>}
             
              <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-custom3">
              {payments.length > 0 ? payments.map((item: Payments) => {return(<ZReportRow key={uuidv4()} title={item.PaymentType} value={item.TotalOrderGroupWithDiscount.toFixed(2)} color={`${item.IdPaymentType === 13 ? "bg-custom-bgOrange" : ""}`}/>)}) : <></>}
              <ZReportRow title={t("periodReport.total")} value={payments.reduce((total: number, item: any) => total + item.TotalOrderGroupWithDiscount, 0).toFixed(2)} color="bg-custom-bgPink"  classes="mt-2" fontSize="text-xs"/>
              </Wrapper>
   
            {details.length > 0 && <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.dailyTotals")}
            </p>}
            <Wrapper classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-custom3" onClick={() => {}} >
              <ZReportRow title={t("periodReport.totalDays")} value={details?.length} color="bg-custom-bgPink"/>
              <ZReportRow title={t("periodReport.turnover")} value={totalAmount?.TotalOrder.toFixed(2)} color="bg-custom-bgPink"/>
              <ZReportRow title={t("periodReport.discounts")} value={totalAmount?.Discount.toFixed(2)} color="bg-custom-bgPink"/>
              <ZReportRow title={t("periodReport.total")} value={totalAmount?.TotalWithDiscount.toFixed(2)} color="bg-custom-bgPink" classes="py-2" borderRadius="rounded-md" fontSize="text-xs"/>
            </Wrapper> 

              {sumsByStatus.length > 0 && <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.other")}
            </p>}
            <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-custom3">
            {sumsByStatus.map((item: any) => {
            return(
              <ZReportRow key={uuidv4()} title={item.status} value={item.quantity.toFixed(2)}/>
            )
          })}
            </Wrapper>
          {corrections &&   <p className="text-base md:text-xl text-custom-textBlackColor">
            {/* {t("zreport.other")} */}
            </p>}
            <Wrapper classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-custom3" onClick={() => {}}>
            <ZReportRow title={`${t("periodReport.corrections")} (${corrections?.length}x)`} value={corrections.reduce((sum: any, item: any) => sum + item.Quantity, 0).toFixed(2)}/>
            <ZReportRow title={`${t("periodReport.discounts")} (${discounts?.length}x)`} value={discounts.reduce((sum: any, item: any) => sum + item.DiscountAmount, 0).toFixed(2)}/>
            {outOfTurnover.detailsOOT.length > 0 ? ( <ZReportRow title={`${t("zreport.outOfTurnover")} (${outOfTurnover.detailsOOT.length}x)`} value={outOfTurnover && outOfTurnover.totalAmountOOT.TotalWithDiscount} color="bg-custom-bgOrange"/>) : (<></>)}
            </Wrapper>
            </div>

            <div className="hidden md:flex w-full gap-4">
              <div className="w-1/2">
              {payments.length > 0 && <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.payment")}
            </p>}
              <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-custom3">
              {payments.length > 0 ? payments.map((item: any) => {return(<ZReportRow key={uuidv4()} title={item.PaymentType} value={item.TotalOrderGroupWithDiscount.toFixed(2)} color={`${item.IdPaymentType === 13 ? "bg-custom-bgOrange" : ""}`}/>)}) : <></>}
              <ZReportRow title={t("periodReport.total")} value={payments.reduce((total: any, item: any) => total + item.TotalOrderGroupWithDiscount, 0).toFixed(2)} color="bg-custom-bgPink"  classes="mt-2" fontSize="text-base"/>
              </Wrapper>

            {details.length > 0 && 
            <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.dailyTotals")}
            </p>
            }
            <Wrapper classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-custom3" onClick={() => {}} >
              <ZReportRow title={t("periodReport.totalDays")} value={details?.length} color="bg-custom-bgPink"/>
              <ZReportRow title={t("periodReport.turnover")} value={totalAmount?.TotalOrder.toFixed(2)} color="bg-custom-bgPink"/>
              <ZReportRow title={t("periodReport.discounts")} value={totalAmount?.Discount.toFixed(2)} color="bg-custom-bgPink"/>
              <ZReportRow title={t("periodReport.total")} value={totalAmount?.TotalWithDiscount.toFixed(2)} color="bg-custom-bgPink" classes="py-2" borderRadius="rounded-md" fontSize="text-base"/>
            </Wrapper> 
         {sumsByStatus.length > 0 && <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.other")}
            </p>}
            <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-custom3">
            {sumsByStatus.map((item: any) => {
            return(
              <ZReportRow key={uuidv4()} title={item.status} value={item.quantity.toFixed(2)}/>
            )
          })}
            </Wrapper>
           {corrections &&  <p className="text-base md:text-xl text-custom-textBlackColor">
            {/* {t("zreport.other")} */}
            </p>}
            <Wrapper classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-custom3" onClick={() => {}}>
            <ZReportRow title={`${t("periodReport.corrections")} (${corrections?.length}x)`} value={corrections.reduce((sum: any, item: any) => sum + item.Quantity, 0).toFixed(2)}/>
            <ZReportRow title={`${t("periodReport.discounts")} (${discounts?.length}x)`} value={discounts.reduce((sum: any, item: any) => sum + item.DiscountAmount, 0).toFixed(2)}/>
            {outOfTurnover.detailsOOT.length > 0 ? ( <ZReportRow title={`${t("zreport.outOfTurnover")} (${outOfTurnover.detailsOOT.length}x)`} value={outOfTurnover && outOfTurnover.totalAmountOOT.TotalWithDiscount} color="bg-custom-bgOrange"/>) : (<></>)}
            </Wrapper>

              </div>
              <div className="w-1/2">
         

            {taxExcludeVat.length > 0 ? (
              <>
                 <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.excludedVAT")}
            </p>
              <ExcludedVatComponent taxExcludeVat={taxExcludeVat}/>
              </> 
          ) : (<></>)}

            {tax.length > 0 ? (  
            <>
            <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.tax")}
            </p>
              <TaxComponent tax={tax}/>
              </>
          ) : (<></>)}

            {turnover.length > 0 ? (   
                           <>
                           <p className="text-base md:text-xl text-custom-textBlackColor">
             {t("zreport.turnover")}
             </p>
                <TurnoverComponent turnover={turnover}/>
               </>       
          ) : (<></>)}

              {isTaxParentGroup && taxParentGroupDetails.length > 0 ? 
              <>
              <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.parentTaxGroup")}
            </p>
              <TaxParentGroupComponent taxParentGroupDetails={taxParentGroupDetails} />
              </>
             : <></>}

              </div>
              
            </div>
            </>
          
          
          ) : (<><div className="text-gray-500 pt-12 h-screen">{t("errorMessages.noDataToBeShownForThisPeriod")}</div></>)}
          </div>
           <ScrollToTopButton />
    </Wrapper>
  );
};

export default PeriodReports;
