import { useMemo } from 'react';

// Define TypeScript interfaces for the expected data types
interface TimePeriod {
  Id: string;
  Description: string;
}

interface MainCategory {
  Id: number;
  Description: string;
}

interface TaxParentType {
  Percentage: number;
  VatName: string;
}

interface TaxType {
  Percentage: number;
  Id: number;
  VatName: string;
}

const useOptions = (
  timePeriod: TimePeriod[],
  mainCategory: MainCategory[],
  taxParentType: TaxParentType[],
  taxType: TaxType[]
) => {
  const timePeriodOptions = useMemo(
    () => timePeriod.map((period) => ({
      value: period?.Id,
      label: period.Description
    })),
    [timePeriod]
  );

  const mainCategoryOptions = useMemo(
    () => mainCategory.map((category) => ({
      value: category?.Id,
      label: category.Description
    })),
    [mainCategory]
  );

  const taxParentTypeOptions = useMemo(
    () => taxParentType.map((category) => ({
      value: category.Percentage,
      label: category.VatName
    })),
    [taxParentType]
  );

  const taxTypeOptions = useMemo(
    () => taxType.map((category) => ({
      id: category.Percentage,
      value: category?.Id,
      label: category.VatName
    })),
    [taxType]
  );

  return {
    timePeriodOptions,
    mainCategoryOptions,
    taxParentTypeOptions,
    taxTypeOptions
  };
};

export default useOptions;
