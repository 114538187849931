import { useTranslation } from "react-i18next";
import Wrapper from "../common/wrapper/Wrapper";
import ZReportRow from "../zReportRow/ZReportRow";
import { v4 as uuidv4 } from 'uuid';

interface Props {
    tax: any;
}

const getSum = (items: any) => {
    return items.reduce((total: any, item: any) => total + item.Ammount, 0);
  }


const TaxComponent: React.FC<Props> = ({tax}) => {
    const { t } = useTranslation();

  return (
    <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg">
    {tax.length > 0 && tax.map((item: any) => {
      return (
        <ZReportRow key={uuidv4()} title={item.VatName} value={item.Ammount.toFixed(2)} />
      );
    })}
    <ZReportRow title={t("zreport.total")} value={tax && getSum(tax).toFixed(2)} color="bg-custom-bgPink"  classes="mb-2" fontSize="text-xs md:text-base"/>
  </Wrapper>
  );
};

export default TaxComponent;
