import { useTranslation } from "react-i18next";
import Wrapper from "../common/wrapper/Wrapper";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Heading from "../common/heading/Heading";
import { Doughnut } from "react-chartjs-2";
import { Props } from "./paymentTypeChart.type";

ChartJS.register(ArcElement, Tooltip, Legend);

const PaymentTypeChart: React.FC<Props> = ({ backendData }) => {
  const { t } = useTranslation();

  const labels = backendData.map((item) => item.PaymentType);
  const amounts = backendData.map((item) => item.Amount);

  const totalAmount = amounts.reduce((acc, cur) => acc + cur, 0);

  const percentages = amounts.map((amount) =>
    ((amount / totalAmount) * 100).toFixed(2),
  );

  const labelsWithPercentages = labels.map((label, index) => 
    `${label}: ${percentages[index]}%`
  );

  const data = {
    labels: labelsWithPercentages,
    datasets: [
      {
        label: "",
        data: amounts,
        backgroundColor: [
          "#AC63E9", // Light Purple
          "#3C0F5C", // Purple
          "#8231BF", // Purple
          "#5A257E", // Dark Purple
          "#A8EFF9", // Light Blue
          "#0D8BAD", // Blue
          "#266F2A", // Dark Green
          "#12708C", // Dark Blue
          "#2C8D31", // Green
          "#3BAC41", // Green
          "#96DF99", // Light Green
          "#1E4921", // Dark Green
        ],
        
        // borderColor: [
        //   "#57A1A6",
        //   "#DAE883",
        //   "#FF9AEF",
        //   "#ABEFC6",
        //   "#D95656",
        //   "#7F56D9",
        //   "#B692F6",
        //   "#067647",
        //   "#2A7AFF",
        //   "#E9D7FE",
        //   "#B98BC5",
        // ],
        pointStyle: 'circle' // Explicitly set point style
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Hide the built-in legend
      },
      // legend: {
      //   // position: "right" as const,
      //   labels: {
      //     usePointStyle: true,
      //     pointStyle: 'circle',
          
      //   },
      // },
      // tooltip: {
      //   callbacks: {
      //     label: function (context: any) {
      //       let label = context.label || "";
      //       if (label) {
      //         label += ": ";
      //       }
      //       const value = context.formattedValue;
      //       const percent = percentages[context.dataIndex];
      //       return `${label}${value} (${percent}%)`;
      //     },
      //   },
      // },
      datalabels: {
        display: false,
        color: "white"
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    radius: "100%", // adjust the radius of the circle
    animation: {
      duration: 1000,
    },
  };

  return (
    <Wrapper
      onClick={() => {}}
      classes="w-full h-[335px] bg-white rounded-lg  px-4 md:px-12 md:pt-7  flex flex-col pb-0 shadow-custom3"
    >
      <Heading
        children={t("sales.paymentType")}
        align=""
        classes="black text-left"
        color=""
        size="h1"
      />
      {!backendData ||
      (Array.isArray(backendData) && backendData.length === 0) ? (
        <p className="pt-4 pb-4">{t("errorMessages.noDataAvailable")}</p>
      ) : (
        <div className="flex flex-row justify-between items-center h-full">
        <div className="w-[140px] h-[140px]">
  <Doughnut data={data} options={options}></Doughnut>
</div>
<div className="ml-6 flex flex-col justify-center flex-wrap z-50">
  {labelsWithPercentages.map((label, index) => (
    <p key={index} className="text-sm text-gray-700 flex items-center">
      <span
        className="w-[10px] h-[10px] rounded-full inline-block mr-2"
        style={{ backgroundColor: data.datasets[0].backgroundColor[index] }}
      ></span>
      {label}
    </p>
  ))}
</div>


      </div>
        // <div className="h-full flex text-center justify-between items-center">
        //   <Doughnut data={data} options={options}></Doughnut>
        // </div>
      )}
    </Wrapper>
  );
};

export default PaymentTypeChart;
