import Wrapper from "../common/wrapper/Wrapper";
import { useContext, useEffect, useState } from "react";
import { LoadingLiveDataContext } from "../../context/isLoadingLiveDataContext";
import useAxios from "../../hooks/useAxios";
import { API_ROUTES } from "../../config";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SoldProducts, SoldProductsProps } from "./SoldProductsReports.type";
import SoldProductsTable from "../soldProductsTable/SoldProductsTable";
import ArrowUpIcon from "../icons/ArrowUpIcon";
import ArrowDownIcon from "../icons/ArrowDownIcon";
import ScrollToTopButton from "../common/scrollToTopButton/ScrolToTopButton";
import { groupData, transformGroupedDetails } from "../../utilities/soldProductsUtils";
import { getTableOptions } from "./soldProductsTableOptions";
import { getProductsTableHeaders } from "./soldProductsTableConfig";
import ExportFilterActions from "../common/reportAction/ExportFilterActions";
import SoldProductsReportFilters from "../soldProductsReportFilters/SoldProductsReportFilter";

const SoldProductsReports: React.FC<SoldProductsProps> = () => {
  const { setIsLoadingLiveData } = useContext(LoadingLiveDataContext);
  const { fetchData } = useAxios();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const {t} = useTranslation();
  const [soldProducts, setSoldProducts] = useState<SoldProducts[]>([])
  const [flattenedProducts, setFlattenedProducts] = useState<SoldProducts[]>([]);
  const [products, setProducts] = useState<SoldProducts[]>([])
  const [couverts, setCouverts] = useState<SoldProducts[]>([])
  const [outOfTurnoverProducts, setOutOfTurnoverProducts] = useState<SoldProducts[]>([])
  const [openSection, setOpenSection] = useState<string | null>(null);
  const [fileUrl, setFileUrl] = useState<null | string>(null);
  const [exportType, setExportType] = useState<any>("0");
  const menuId = localStorage.getItem("MI")
  const [filters, setFilters] = useState<any>();


    
  const updateFiltersWithExportType = (filters: any, exportType: any, menuId: any) => {
    return {
      ...filters,
      ExportType: exportType,
      IdMenu: menuId
    };
  };
  
  const getDataBe = () => {
    if (
      location.pathname === "/reports/soldProducts"
    ) {
      const updatedFilters = updateFiltersWithExportType(filters, exportType, menuId);

      if (filters) {
        const getData = async () => {
          const requestBody = updatedFilters;
          
          setIsLoadingLiveData(true);
          
          try {
            const apiResponse = await fetchData(API_ROUTES.getSoldProducts, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: requestBody,
            });
            setSoldProducts(apiResponse.data.SoldProducts)
            setFileUrl(apiResponse.data.DocumentUrl.FileName)
          } catch (error) {
            setSoldProducts([])
            setFileUrl("")
          } finally {
            setIsLoadingLiveData(false);
          }
        };
        
        getData();
      }
    }
  }

  useEffect(() => {
    if (soldProducts.length > 0) {
      const grouped = soldProducts.reduce((acc: any, product: any) => {
        const { MainCategory, SubCategory, ProductType } = product;
  
        // Only include products with ProductType 0
        if (ProductType === 0) {
          if (!acc[MainCategory]) {
            acc[MainCategory] = {};
          }
          if (!acc[MainCategory][SubCategory]) {
            acc[MainCategory][SubCategory] = [];
          }
  
          acc[MainCategory][SubCategory].push(product);
        }
        
        return acc;
      }, {});
  
      const flattened: SoldProducts[] = [];
      for (const mainCategory in grouped) {
        for (const subCategory in grouped[mainCategory]) {
          grouped[mainCategory][subCategory].forEach((product: SoldProducts) => {
            flattened.push({
              ...product,
              MainCategory: mainCategory,
              SubCategory: subCategory,
            });
          });
        }
      }
      setFlattenedProducts(flattened);
    }
  }, [soldProducts]);
  

  useEffect(() => {
    if (soldProducts.length > 0) {
      const productsArray: SoldProducts[] = [];
      const couvertsArray: SoldProducts[] = [];
      const outOfTurnoverProductsArray: SoldProducts[] = [];
      soldProducts.forEach((product: SoldProducts) => {
        if (product.ProductType == 0) {
          productsArray.push(product);
        } else if (product.ProductType == 1) {
          couvertsArray.push(product);
        } else if (product.ProductType == 3) {
          outOfTurnoverProductsArray.push(product);
        }
      });
  
      setProducts(productsArray);
      setCouverts(couvertsArray);
      setOutOfTurnoverProducts(outOfTurnoverProductsArray);
    }
  }, [soldProducts])

  const toggleSection = (section: string) => {
    setOpenSection(openSection === section ? null : section);
  };
  
  const groupedDetails = groupData(flattenedProducts);
  const groupedCouverts = groupData(couverts);
  const groupedOOT = groupData(outOfTurnoverProducts);

  const transformedDetails = transformGroupedDetails(groupedDetails, 0, t);
  const transformedCouverts = transformGroupedDetails(groupedCouverts, 1, t);
  const transformedOOT = transformGroupedDetails(groupedOOT, 3, t);

  const productsTH = getProductsTableHeaders(t); 

  const options = getTableOptions(transformedCouverts, productsTH);
  const options2 = getTableOptions(transformedDetails, productsTH);
  const options3 = getTableOptions(transformedOOT, productsTH);
  const handleExportChange = (exportType: any) => {
    setExportType(exportType[0])
  }

  const productsTotals = products.reduce(
    (acc, pr: any) => {
      return {
        totalQuantity: acc.totalQuantity + pr.Quantity,
        totalSubTotal: acc.totalSubTotal + pr.SubTotal,
        totalTaxExcludedWithDiscount: acc.totalTaxExcludedWithDiscount + pr.TaxExcludedWithDiscount,
        totalTotal: acc.totalTotal + pr.Total,
      };
    },
    {
      totalQuantity: 0,
      totalSubTotal: 0,
      totalTaxExcludedWithDiscount: 0,
      totalTotal: 0,
    }
  );
  const couvertsTotals = couverts.reduce(
    (acc, pr: any) => {
      return {
        totalQuantity: acc.totalQuantity + pr.Quantity,
        totalSubTotal: acc.totalSubTotal + pr.SubTotal,
        totalTaxExcludedWithDiscount: acc.totalTaxExcludedWithDiscount + pr.TaxExcludedWithDiscount,
        totalTotal: acc.totalTotal + pr.Total,
      };
    },
    {
      totalQuantity: 0,
      totalSubTotal: 0,
      totalTaxExcludedWithDiscount: 0,
      totalTotal: 0,
    }
  );
  const OOTTotals = outOfTurnoverProducts.reduce(
    (acc, pr: any) => {
      return {
        totalQuantity: acc.totalQuantity + pr.Quantity,
        totalSubTotal: acc.totalSubTotal + pr.SubTotal,
        totalTaxExcludedWithDiscount: acc.totalTaxExcludedWithDiscount + pr.TaxExcludedWithDiscount,
        totalTotal: acc.totalTotal + pr.Total,
      };
    },
    {
      totalQuantity: 0,
      totalSubTotal: 0,
      totalTaxExcludedWithDiscount: 0,
      totalTotal: 0,
    }
  );
  const handleFilters = (filters: any) => {
    setFilters(filters)
  }
  return (
    <Wrapper onClick={() => {}} classes={`w-[95%] mx-auto pt-2 md:pt-4 pb-16 min-h-screen`}>
      <SoldProductsReportFilters sendFiltersToParent={handleFilters} exportType={exportType} fileUrl={fileUrl} getDataBe={getDataBe} handleExportChange={handleExportChange}/>
      <div className={`relative pt-[29rem] md:pt-[25rem]`} style={{ maxHeight: "95dvh", overflowY: "auto" }}>

        {soldProducts.length > 0 ? <>

      {couverts.length > 0 ? (<div onClick={() => toggleSection("couverts")} className="flex justify-between items-center px-2 md:px-4 rounded-lg border border-gray-200 w-full relative my-2 mb-0 py-2 md:py-4 bg-white shadow-custom3">{t("soldProducts.couverts")} {openSection === "couverts" ? (<ArrowUpIcon color="black"/>) : (<ArrowDownIcon color="black"/>)} </div>) : (<></>)}
      {couverts.length > 0 && openSection === "couverts" ? <SoldProductsTable options={options}  details={couverts}/> : <></>}  

      {products.length > 0 ? (<div onClick={() => toggleSection("days")} className="flex justify-between items-center px-2 md:px-4 rounded-lg border border-gray-200 w-full relative my-2 mb-0 py-2 md:py-4 mt-4 bg-white shadow-custom3">{t("soldProducts.products")} {openSection === "days" ? (<ArrowUpIcon color="black"/>) : (<ArrowDownIcon color="black"/>)} </div>) : (<></>)}
      {products.length > 0 && openSection === "days" ? <SoldProductsTable options={options2}  details={flattenedProducts}/> : <></>}
    
      {outOfTurnoverProducts.length > 0 ? (<div onClick={() => toggleSection("outOfTurnover")} className="flex justify-between items-center px-2 md:px-4 rounded-lg border border-custom-bgOrange w-full relative my-2 mb-0 py-2 md:py-4 mt-4 bg-custom-bgOrange text-white shadow-custom3">{t("soldProducts.outOfTurnover")} {openSection === "outOfTurnover" ? (<ArrowUpIcon color="white"/>) : (<ArrowDownIcon color="white"/>)} </div>) : (<></>)}
      {outOfTurnoverProducts.length > 0 && openSection === "outOfTurnover" ? <SoldProductsTable options={options3}  details={outOfTurnoverProducts} borderColor="border-custom-bgOrange" totalColor="bg-custom-bgOrange" /> : <></>}  
      </> : <div className="text-gray-500 pt-12 h-screen">{t("errorMessages.noDataToBeShownForThisPeriod")}</div> }
    
      {productsTotals.totalQuantity !== 0 || couvertsTotals.totalQuantity !== 0 || OOTTotals.totalQuantity !== 0 ? 
      <div className="w-full rounded-xl bg-custom-tableBgPurple py-2 md:py-3 px-2 md:px-4 mt-8 flex justify-between">
        <span>{t("soldProducts.total")}</span>
        <span>{productsTotals.totalQuantity + couvertsTotals.totalQuantity + OOTTotals.totalQuantity}</span>
        <span>{(productsTotals.totalSubTotal + couvertsTotals.totalSubTotal + OOTTotals.totalSubTotal).toFixed(2)}</span>
        <span>{(productsTotals.totalTaxExcludedWithDiscount + couvertsTotals.totalTaxExcludedWithDiscount + OOTTotals.totalTaxExcludedWithDiscount).toFixed(2)}</span>
        <span>{(productsTotals.totalTotal + couvertsTotals.totalTotal + OOTTotals.totalTotal).toFixed(2)}</span>
      </div>
      : <></>
      }
      </div>
      <ScrollToTopButton />
    </Wrapper>
  );
};

export default SoldProductsReports;
