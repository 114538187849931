import React, { createContext, useState, ReactNode } from "react";
import { useCookies } from "react-cookie";

interface MenuItem {
  IdMenu: number;
  MenuName: string;
}

interface ReportsContextType {
  shouldRenderReports: boolean;
  setShouldRenderReports: React.Dispatch<React.SetStateAction<boolean>>;
  restaurantMenus: MenuItem[]; // Define restaurantMenus state
  setRestaurantMenus: React.Dispatch<React.SetStateAction<MenuItem[]>>; // Define setter for restaurantMenus
  menuId: any,
  setMenuId: any,
  filter: any,
  setFilter: any
}

export const PrivilegesContext = createContext<ReportsContextType>({
  shouldRenderReports: false,
  setShouldRenderReports: () => {},
  restaurantMenus: [],
  setRestaurantMenus: () => {}, // Initialize setter function
  menuId: null, 
  setMenuId: () => {},
  filter: [],
  setFilter: () => {}
});

export const PrivilegesProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [cookies] = useCookies(["isLogIn"]); // Include the cookie name you want to access

  // Access the value of the cookie
  const isLoggedIn = cookies.isLogIn;
  const [shouldRenderReports, setShouldRenderReports] =
    useState<boolean>(isLoggedIn);
    
  const test = localStorage.getItem("menuId");
  const parsedTest = test && JSON.parse(test);
  const [restaurantMenus, setRestaurantMenus] = useState<MenuItem[]>([]);
  const [menuId, setMenuId] = useState<any>(parsedTest && parsedTest.value);
  const [filter, setFilter] = useState<any[]>();

  // useEffect(() => {
  //   setMenuId(UserMenus)
  // }, [UserMenus])

  // console.log(menuId)

  

  return (
    <PrivilegesContext.Provider
      value={{
        shouldRenderReports,
        setShouldRenderReports,
        restaurantMenus,
        setRestaurantMenus,
        menuId,
        setMenuId,
        filter, 
        setFilter
      }}
    >
      {children}
    </PrivilegesContext.Provider>
  );
};
