import Wrapper from "../common/wrapper/Wrapper";
import { useContext, useEffect, useState } from "react";
import i18n from "../../i18n";
import { LoadingLiveDataContext } from "../../context/isLoadingLiveDataContext";
import useAxios from "../../hooks/useAxios";
import { API_ROUTES } from "../../config";
import { useLocation } from "react-router-dom";
import ScrollToTopButton from "../common/scrollToTopButton/ScrolToTopButton";
import { ReceiptProps } from "./ReceiptReport.type";
import { useTranslation } from "react-i18next";
import Table from "../common/table/Table";
import { getDetailsTableOptions, getTableOptions } from "./ReciptReportTableOptions";
import { getDetailTableHeaders, getTableHeaders } from "./ReciptReportTableConfig";
import ReceiptReportFilters from "../receiptReportFilters/ReceiptReportFilters";
import useDateFormatter from "../../hooks/useDateFormatter";
import FilterPopover from "../common/table/FilterPopover";
import FilterIcon from "../icons/FilterIcon";
import useIsMobileDevice from "../../hooks/useIsMobileDevice";
import ResetIcon from "../icons/ResetIcon";
import ArrowUpIcon from "../icons/ArrowUpIcon";
import ArrowDownIcon from "../icons/ArrowDownIcon";




const ReceiptReports: React.FC<ReceiptProps> = () => {
  const { setIsLoadingLiveData } = useContext(LoadingLiveDataContext);
  const { fetchData } = useAxios();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const {t} = useTranslation();
  const [exportType, setExportType] = useState<any>("0");
  const [fileUrl, setFileUrl] = useState<null | string>(null);
  const menuId = localStorage.getItem("MI")
  const [data, setData] = useState<any>([])
  const [receiptId, setReceiptId] = useState<any>(null);
  const [detailsOn, setDetailsOn] = useState<boolean>(false);
  const [paymentsDetails, setPaymentsDetails] = useState<any>([])
  const { formatDateSP } = useDateFormatter();
  const [fromDate, setFromDate] = useState<any>(formatDateSP(new Date(new Date().setDate(new Date().getDate() - 1))));



  const handleTableUpdate = (newReceiptId: any, newDetailsOn: boolean) => {
    setReceiptId(newReceiptId);
    setDetailsOn(newDetailsOn);
    setDetailsFilter([0])
  };

  const getBEData = async () =>  {
    if (location.pathname === "/reports/receiptReport" && menuId && fromDate) {
          const requestBody = {
            IdMenu: menuId,
            TargetDate: fromDate,
            Language: i18n.language,
            ExportType: exportType,
        }
          localStorage.setItem('receiptFilter', "[0]")
          setFilter([0])
          setIsLoadingLiveData(true);
  
          try {
            const apiResponse = await fetchData(API_ROUTES.getReceiptReport, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: requestBody,
            });
  

            if(apiResponse.data.Receipts) {
              setData(apiResponse.data.Receipts)
            } else {
              setData([])
            }
            if(apiResponse.data.DocumentUrl){
              setFileUrl(apiResponse.data.DocumentUrl.FileName)
            } else {
              setFileUrl(null)
            }
            setDetailsOn(false)
          } catch (error) {
            console.log(error)
            setData([])
            // console.error("Error fetching data:", error);
          } finally {
            setIsLoadingLiveData(false);
          }
    }
  };

const handleExportChange = (exportType: any) => {
  setExportType(exportType[0])
}

const [detailsData, setDetailsData] = useState<any>([])
const [dataById, setDataById] = useState<any>()
useEffect(() => {
  if(receiptId !== undefined) {
    const dataById = data.find((item: any) => item.Id === receiptId)
    setDetailsData(dataById ? dataById.ReceiptDetails : [])
    setDataById(dataById)
    setPaymentsDetails(dataById ? dataById.PaymentDetails : [])
  }
}, [receiptId, detailsOn, data])
// localStorage.setItem('receiptFilter', JSON.stringify([]));  // Save to localStorage
const [filter, setFilter] = useState<any[]>(() => {
  // Retrieve filter from localStorage if available
  const storedFilter = localStorage.getItem('receiptFilter');
  return storedFilter ? JSON.parse(storedFilter) : [0]; // Default to 'All Receipts' if nothing is stored
});

const [detailsFilter, setDetailsFilter] = useState<any[]>(() => {
  // Retrieve filter from localStorage if available
  const storedFilter = localStorage.getItem('receiptFilter');
  return storedFilter ? JSON.parse(storedFilter) : [0]; // Default to 'All Receipts' if nothing is stored
});
const [tableFilter, setTableFilter] = useState<any[]>(() => {
  // Retrieve filter from localStorage if available
  const storedFilter = localStorage.getItem('receiptFilter');
  return storedFilter ? JSON.parse(storedFilter) : [0]; // Default to 'All Receipts' if nothing is stored
});

  const handleReceiptsFilterUpdate = (selectedFilter: any) => {
    const filtersFromLocalStorage = localStorage.getItem("receiptFilter")
    if(selectedFilter.length > 0){

      localStorage.setItem('receiptDetailsFilter', JSON.stringify(selectedFilter));  // Save to localStorage
      setFilter(selectedFilter)
    } else if(selectedFilter.length === 0 && filtersFromLocalStorage?.length === 0){
      localStorage.setItem('receiptDetailsFilter', JSON.stringify([0]));  // Save to localStorage
      setFilter([0])

    }
};
const handleReceiptsTableFilterUpdate = (selectedFilter: any) => {
  const filtersFromLocalStorage = localStorage.getItem("receiptTableFilter")
  if(selectedFilter.length > 0){

    localStorage.setItem('receiptTableFilter', JSON.stringify(selectedFilter));  // Save to localStorage
    setTableFilter(selectedFilter)
  } else if(selectedFilter.length === 0 && filtersFromLocalStorage?.length === 0){
    localStorage.setItem('receiptTableFilter', JSON.stringify([0]));  // Save to localStorage
    setTableFilter([0])

  }
};
const handleReceiptsDetailsFilterUpdate = (selectedFilter: any) => {
  const filtersFromLocalStorage = localStorage.getItem("receiptFilter")
  if(selectedFilter.length > 0){

    localStorage.setItem('receiptFilter', JSON.stringify(selectedFilter));  // Save to localStorage
    setDetailsFilter(selectedFilter)
  } else if(selectedFilter.length === 0 && filtersFromLocalStorage?.length === 0){
    localStorage.setItem('receiptFilter', JSON.stringify([0]));  // Save to localStorage
    setDetailsFilter([0])

  }
};

const filteredData = data?.filter((item: any) => {
  const receiptDetails = item.ReceiptDetails || [];
  const isCorrection = receiptDetails.some((detail: any) => detail.Quantity < 0);
  const isPotentialFraud = receiptDetails.some((detail: any) => detail.IsPotentialFraud);
  const isTransfer = receiptDetails.some((detail: any) => detail.IdTransfer > 0);
  const isOnHouse = receiptDetails.some((detail: any) => detail.IdStatusType == 2);
  const isOwnUse = receiptDetails.some((details: any) => details.IdStatusType == 1);
  const isNoPayment = item.IdPaymentType == 5;

  const tableIdentifier = `${item.TableName}|${item.TableSufix}`;

   if(!tableFilter?.includes(0) && !filter?.includes(0)) {
    const statusConditions = [
      filter?.includes("1") && isCorrection && tableFilter.includes(tableIdentifier),
      isPotentialFraud && filter?.includes("6") && tableFilter.includes(tableIdentifier),
      isTransfer && filter?.includes("2") && tableFilter.includes(tableIdentifier),
      filter?.includes("4") && isOnHouse && tableFilter.includes(tableIdentifier),
      filter?.includes("3") && isOwnUse && tableFilter.includes(tableIdentifier),
      filter?.includes("5") && isNoPayment && tableFilter.includes(tableIdentifier)
    ];

    if (statusConditions.some(Boolean)) {
      return true;
    }
  } else if((filter?.length === 0 || filter?.includes(0) || filter?.includes("0")) && (tableFilter?.length === 0 || tableFilter?.includes(0) || tableFilter?.includes("0"))){
    return true;
  } else if(!filter?.includes(0)){
    const statusConditions = [
      filter?.includes("1") && isCorrection ,
      isPotentialFraud && filter?.includes("6"),
      isTransfer && filter?.includes("2") ,
      filter?.includes("4") && isOnHouse ,
      filter?.includes("3") && isOwnUse,
      filter?.includes("5") && isNoPayment,
    ];

    if (statusConditions.some(Boolean)) {
      return true;
    }
  } else if(tableFilter?.length > 0 && tableFilter?.includes(tableIdentifier)) {

    return true;
  }
 
  else {
    return false
  }
});


const tableTH = getTableHeaders(t);
const detailsTableTH = getDetailTableHeaders(t, fromDate);
const options = getTableOptions(filteredData, tableTH);
const detailsData1 = detailsData.map((item: any) => {
  if ([-10, -1, -3, -4, -8, -9].includes(item.Round)) {
    const updatedItem = { ...item, Quantity: "" };
    if ([-10, -1, -3, -4].includes(item.Round)) {
      updatedItem.Price = "";
    }
    return updatedItem;
  }
  return item;
});
const filteredDetailsData = detailsData1?.filter((item: any) => {
  const isCorrection = item.Quantity < 0;
  const isPotentialFraud = item.IsPotentialFraud;
  const isTransfer = item.IdTransfer > 0;
  const isOnHouse = item.IdStatusType == 2;
  const isOwnUse = item.IdStatusType == 1;

  // const createdTime = new Date(item.DateCreated).toLocaleTimeString();


  // Update DateCreated to only include time if dates match

  if (detailsFilter?.includes("1") && isCorrection) {
    return item;
  }
  
  if(isPotentialFraud && detailsFilter?.includes("6")){
    return item;
  }
  if(isTransfer && detailsFilter?.includes("2")){
    return item;
  }
  if (detailsFilter?.includes(0) || detailsFilter?.includes("0") || detailsFilter?.length == 0) {
    return item;
  }
  if(detailsFilter?.includes("4") && isOnHouse){
    return item;
  }
  if(detailsFilter?.includes("3") && isOwnUse) {
    return item;
  }
 
})


const detailsTableOptions = getDetailsTableOptions(filteredDetailsData, detailsTableTH)

let totalPaymentsAmount;
totalPaymentsAmount = paymentsDetails.length > 0 
  ? paymentsDetails.reduce((sum: number, item: any) => 
      item.Active === true ? sum + item.AmountPaid : sum
    , 0)
  : 0;


  const handleDate = (date: any) => {
    setFromDate(date)
  }
  const handleMenuId = (menuId: any) => {
    localStorage.setItem("MI", menuId)
  }
  const handleFetchData = () => {
    getBEData();
  }

  const isMobile = useIsMobileDevice();
  const receiptsFilterOptions = [
    { value: 0, label: t("receiptReport.receipts")},
    { value: '5', label: t("receiptReport.noPayment") },
    { value: '1', label: t("receiptReport.correction")},
    { value: '2', label: t("receiptReport.transfer")},
    { value: '3', label: t("receiptReport.ownUse")},
    { value: '4', label: t("receiptReport.fromHouse")},
    { value: '6', label: t("receiptReport.correctionPosibleFraud")}
  ]
  const receiptsDetailsFilterOptions = [
    { value: 0, label: t("receiptReport.allDetails") },
    { value: '1', label: t("receiptReport.correction")},
    { value: '2', label: t("receiptReport.transfer")},
    { value: '3', label: t("receiptReport.ownUse")},
    { value: '4', label: t("receiptReport.fromHouse")},
    { value: '6', label: t("receiptReport.correctionPosibleFraud")}
  ]

  const receiptTableFiltersByName = data
  .map((entry: any) => {
      const tableIdentifier = `${entry.TableName}|${entry.TableSufix}`;
      return { value: tableIdentifier, label: tableIdentifier };
  })
  .filter((option: any, index: any, self: any) =>
      index === self.findIndex((o: any) => o.value === option.value)
  );

  // receiptTableFiltersByName.unshift({ value: 0, label: isMobile ? t("receiptReport.tables") : t("receiptReport.tablesNumber") });

  const handleResetFilters = () => {
    setFilter([0])
    setTableFilter([0])
  }
  const language = i18n.language;
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({
    receipts: true,
  });
  const toggleSection = (section: string) => {
    setOpenSections(prevOpenSections => ({
      ...prevOpenSections,
      [section]: !prevOpenSections[section]
    }));
  };

return (
    <Wrapper onClick={() => {}} classes="w-[95%] mx-auto md:pt-4 pb-16 min-h-screen ">
      <ReceiptReportFilters sendDateToParent={handleDate} sendMenuIdToParent={handleMenuId} fetchReportData={handleFetchData} fileUrl={fileUrl} handleExportChange={handleExportChange}/>
      
      <div className="relative pt-[18rem] md:pt-56" style={{ maxHeight: "95dvh", overflowY: "auto" }}>
    {!detailsOn && data.length > 0 &&
    <div className="mt-16">
            <div 
      onClick={() => toggleSection("receipts")} 
      className="flex justify-between items-center mb-0 px-2 md:px-4 rounded-lg border border-gray-200 w-full relative my-2 py-2 md:py-4 bg-white shadow-custom text-sm md:text-base"
      >
          {t("receiptReport.receipts")} {openSections["receipts"] ? (<ArrowUpIcon color="#6941C6"/>) : (<ArrowDownIcon color="#6941C6"/>)} 
        </div>
        {openSections["receipts"] &&
      <div className="w-full flex justify-end">
        <div className={`flex  ${language == "nl" && isMobile ? "w-[80%]" : language == "nl" ? "w-[60%]" : "w-[70%] md:w-[50%]"} right-0 gap-2`}>
      <span className="mt-4">
              <FilterIcon w={isMobile ? "16px" : ""} h={isMobile ? "16px" : ""}/>
      </span>
              <FilterPopover options={receiptTableFiltersByName} onFilterChange={handleReceiptsTableFilterUpdate} selected={tableFilter} isTableFilter={true}/>
              <FilterPopover options={receiptsFilterOptions} onFilterChange={handleReceiptsFilterUpdate} selected={filter}/>
              <button onClick={handleResetFilters} className="cursor-pointer flex items-center justify-around py-1.5 px-1.5 md:py-2 md:w-[60%] max-h-8 md:max-h-10 mt-[9px] text-custom-red border border-custom-red rounded-lg">
                {!isMobile && t("receiptReport.resetFilters") }
                <ResetIcon />
              </button>
        </div>
      </div>
}

      {openSections["receipts"] && <Table options={options} onUpdate={handleTableUpdate} detailsOn={detailsOn} isAllowedFiltering={true} fromDate={fromDate} isReceiptTable={true}/>}
    </div>
    }
    {detailsOn && detailsData.length > 0 &&
    <div className="mt-16">
      <div className="flex justify-end">

      <span className="md:w-1/5 flex">
        <span className="mt-4 mr-2">

      <FilterIcon w={isMobile ? "16px" : ""} h={isMobile ? "16px" : ""}/>
        </span>

              <FilterPopover options={receiptsDetailsFilterOptions} onFilterChange={handleReceiptsDetailsFilterUpdate} selected={detailsFilter}/>
      </span>
      </div>
      <Table options={detailsTableOptions} dataById={dataById} detailsOn={detailsOn} onUpdate={handleTableUpdate} isDetailTable={true} />
    </div>
    }
  </div>
<ScrollToTopButton />

    </Wrapper>
  );
};

export default ReceiptReports;
