import { useContext, useEffect, useState } from "react";
import ExportFilterActions from "../common/reportAction/ExportFilterActions";
import Wrapper from "../common/wrapper/Wrapper";
import { LoadingLiveDataContext } from "../../context/isLoadingLiveDataContext";
import useAxios from "../../hooks/useAxios";
import i18n from "../../i18n";
import { API_ROUTES } from "../../config";
import { useLocation } from "react-router-dom";


interface ExportReceiptProps {
    selectedIds: any;
    fromDate: any;
}

const ExportReceipt: React.FC<ExportReceiptProps> = ({selectedIds, fromDate}) => {
    const { setIsLoadingLiveData } = useContext(LoadingLiveDataContext);
  const { fetchData } = useAxios();
    const menuId = localStorage.getItem("MI")
    const token = localStorage.getItem("token");
    const [receiptsPdf, setReceiptsPdf] = useState<string>();
    const location = useLocation();
    const [exportType, setExportType] = useState<any>(0)

    useEffect(() => {
      if(!selectedIds){
        setExportType(0)
      }
    }, [selectedIds])

    const getReceiptsPdf = async (): Promise<void> => {
      if (location.pathname === "/reports/receiptReport" && menuId && fromDate) {
              const requestBody = {
                IdMenu: menuId,
                TargetDate: fromDate,
                Language: i18n.language,
                ExportType: exportType,
                IdBills: selectedIds
            }
              setIsLoadingLiveData(true);
    
              try {
                const apiResponse = await fetchData(API_ROUTES.getReceiptReport, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                  data: requestBody,
                });
                if(apiResponse.data.DocumentUrl){   
                  window.open(apiResponse.data.DocumentUrl.FileName, "_blank",  "noopener,noreferrer")
                  setReceiptsPdf(apiResponse.data.DocumentUrl.FileName)
                } 
              } catch (error) {
                console.log(error)
              } finally {
                setIsLoadingLiveData(false);
              }
        }
      };

      const handleExportChange = (selected: any) => {
        setExportType(selected[0])
      }
return (
    <Wrapper onClick={() => {}} classes="w-full mx-auto md:pt-4 pb-16 min-h-screen ">
    {selectedIds && 
      <div className="pt-4">

{/* <iframe src={receiptsPdf} width="100%" height="500px"></iframe> */}

      <ExportFilterActions exportType={exportType} fetchReportData={getReceiptsPdf} fileUrl={""} handleExportChange={handleExportChange} hideCSV={true} isReceiptsExport={true} receiptsPdf={receiptsPdf}/>
      </div>}
    </Wrapper>
  );
};

export default ExportReceipt;
