import Wrapper from "../common/wrapper/Wrapper";
import { useContext, useEffect, useState } from "react";
import { LoadingLiveDataContext } from "../../context/isLoadingLiveDataContext";
import useAxios from "../../hooks/useAxios";
import { API_ROUTES } from "../../config";
import { useLocation } from "react-router-dom";
import ZReportRow from "../zReportRow/ZReportRow";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import { PaymentType, Props } from "./PercentageReport.type";
import ScrollToTopButton from "../common/scrollToTopButton/ScrolToTopButton";
import ArrowUpIcon from "../icons/ArrowUpIcon";
import ArrowDownIcon from "../icons/ArrowDownIcon";
import PercentageReportTable from "../percentageReportTable/PercentageReportTable";
import PeriodReportTable from "../periodReportTable/PeriodReportTable";
import { groupData, transformGroupedDetails } from "../../utilities/percentageReportUtils";
import { getDiscountsTH, getProductsTH, getVatTotalsTH } from "./PercentageReportTableConfig";
import { getTableOptions } from "./PercentageReportOptions";
import ExportFilterActions from "../common/reportAction/ExportFilterActions";
import SoldProductsTable from "../soldProductsTable/SoldProductsTable";
import PercentageReportFilters from "../percentageReportFilters/PercentageReportFilters";
import usePaymentTypeMap from "../../hooks/usePaymentTypeMap";

type VatEntry = {
  VatName: string;
  Turnover: number;
  VAT: number;
};

const PercentageReport: React.FC<Props> = () => {
  const { setIsLoadingLiveData } = useContext(LoadingLiveDataContext);
  const { fetchData } = useAxios();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const [paymentData, setPaymentData] = useState<PaymentType[]>([]);
  const [corrections, setCorecctions] = useState<any>([]);
  const {t} = useTranslation();
  const [fileUrl, setFileUrl] = useState<null | string>(null);
  const [percentagesData, setPercentagesData] = useState<any>([]);
  const [tips, setTips] = useState<any>([]);
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({
    sales: false,
    vatTotals: false,
    payments: false,
    discounts: false,
    tips: false,
    serviceFee: false,
    allTaxes: false,
    outOfTurnover: false
  });
  const [products, setProducts] = useState<any>([])
  const [flattenedProducts, setFlattenedProducts] = useState<any>([]);
  const [vatTotals, setVatTotals] = useState<any>([])
  const [discounts, setDiscounts] = useState<any>([])
  const [serviceFee, setServiceFee] = useState<any>([])
  const [exportType, setExportType] = useState<any>("0");
  const [allTaxes, setAllTaxes] = useState<any>(null);
  // const {menuId, setMenuId } = useContext(PrivilegesContext);
  const menuId = localStorage.getItem("MI");
  const taxParentToggle = localStorage.getItem("toggle_1")
  const isTaxParentGroup = taxParentToggle ? JSON.parse(taxParentToggle ? taxParentToggle : "") : true;
  const [outOfTurnover, setOutOfTurnover] = useState<any>([])
  const [filters, setFilters] = useState<any>();

    const updateFiltersWithExportType = (filters: any, exportType: any) => {
      return {
        ...filters,
        ExportType: exportType,  // Update the ExportType field
        IdMenu: menuId
      };
    };

  const getDataBe = () => {
    if (
      location.pathname === "/reports/percentageReport"
    ) {

      const updatedFilters = updateFiltersWithExportType(filters, exportType);
      if (filters) {
        const getData = async () => {
          const requestBody = updatedFilters;
          
          setIsLoadingLiveData(true);
          
          try {
            const apiResponse = await fetchData(API_ROUTES.getPercentagesReport, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: requestBody,
            });

            setFileUrl(apiResponse.data.DocumentUrl.FileName)
            setPercentagesData(apiResponse.data.PercentagesData)
            setPaymentData(apiResponse.data.paymentTypes)
            setVatTotals(apiResponse.data.TaxParentGroupDetails)
            setDiscounts(apiResponse.data.discounts)
            setTips(apiResponse.data.Tips)
            setServiceFee(apiResponse.data.ServiceFee)
            setAllTaxes(apiResponse.data.alltaxes)  
          } catch (error) {
            setFileUrl("")
            setPercentagesData([])
            setPaymentData([])
            setVatTotals([])
            setDiscounts([])
            setTips([])
            setServiceFee([])
            setAllTaxes(null)

          } finally {
            setIsLoadingLiveData(false);
          }
        };
        
        getData();
      }
    }
  }

  useEffect(() => {
    if (percentagesData.length > 0) {
      const grouped = percentagesData.reduce((acc: any, product: any) => {
        const { MainCategory, SubCategory } = product;

        if (!acc[MainCategory]) {
          acc[MainCategory] = {};
        }
        if (!acc[MainCategory][SubCategory]) {
          acc[MainCategory][SubCategory] = [];
        }

        acc[MainCategory][SubCategory].push(product);
        return acc;
      }, {});

      const flattened: any = [];
      for (const mainCategory in grouped) {
        for (const subCategory in grouped[mainCategory]) {
          grouped[mainCategory][subCategory].forEach((product: any) => {
            flattened.push({
              ...product,
              MainCategory: mainCategory,
              SubCategory: subCategory,
            });
          });
        }
      }
      setFlattenedProducts(flattened);
    }
  }, [percentagesData]);

let totalCorrcetionsAmount;
let totalPaymentsAmount;
let tipsAmountTotal;
let serviceFeeAmountTotal
{tips.length > 0 ? (tipsAmountTotal = tips.reduce((sum: AudioNode, item: any) => sum + item.Amount, 0)) : (tipsAmountTotal = 0)}
{serviceFee.length > 0 ? (serviceFeeAmountTotal = serviceFee.reduce((sum: any, item: any) => sum + item.Amount , 0)) : (serviceFeeAmountTotal = 0)}
{corrections.length > 0 ? (totalCorrcetionsAmount = corrections.reduce((sum: any, item: any) => sum + item.Quantity, 0)) : (totalCorrcetionsAmount = 0)}
{paymentData.length > 0 ? (totalPaymentsAmount = paymentData.reduce((sum: any, item: any) => sum + item.TotalOrderGroupWithDiscount, 0)) : (totalPaymentsAmount = 0)}

useEffect(() => {
  if (percentagesData.length > 0) {
    const productsArray: any = [];
    const couvertsArray: any = [];
    const outOfTurnoverProductsArray: any = [];
    percentagesData.forEach((product: any) => {
      if (product.ProductType == 0) {
        productsArray.push(product);
      } else if (product.ProductType == 1) {
        couvertsArray.push(product);
      } else if (product.ProductType == 3) {
        outOfTurnoverProductsArray.push(product);
      }
    });
    setOutOfTurnover(outOfTurnoverProductsArray)
    setProducts(productsArray);
  }
}, [percentagesData])

const toggleSection = (section: string) => {
  setOpenSections(prevOpenSections => ({
    ...prevOpenSections,
    [section]: !prevOpenSections[section]
  }));
};

const groupedSales = groupData(flattenedProducts);

const transformedDetails = transformGroupedDetails(groupedSales, 0, t);


  const vatTotalsTH = getVatTotalsTH(t);
  const discountsTH = getDiscountsTH(t);
  const productsTH = getProductsTH(t);

  const options2 = getTableOptions(discounts, discountsTH);
  const options3 = getTableOptions(transformedDetails, productsTH);
  // console.log(vatTotals)
  
  const result = vatTotals.reduce((acc: Record<string, VatEntry>, tax: any) => {
    const { VatName, Ammount, TaxType } = tax;
  
    if (!acc[VatName]) {
      acc[VatName] = { VatName, Turnover: 0, VAT: 0 };
    }
  
    if (TaxType === 2) {
      acc[VatName].VAT += Ammount;
    } else if (TaxType === 3) {
      acc[VatName].Turnover += Ammount;
    }
  
    acc["Total"].VAT += (TaxType === 2) ? Ammount : 0;
    acc["Total"].Turnover += (TaxType === 3) ? Ammount : 0;
  
    return acc;
  }, { "Total": { VatName: "Total", Turnover: 0, VAT: 0 } } as Record<string, VatEntry>);
  
  let finalResult = Object.entries(result)
  .filter(([key]) => key !== "Total") // Filter out "Total" temporarily
  .map(([, value]) => {
    const entry = value as VatEntry; // Cast to VatEntry type
    return {
      ...entry,
      Turnover: parseFloat(entry.Turnover.toFixed(2)),
      VAT: parseFloat(entry.VAT.toFixed(2)),
    };
  });
  
  finalResult.push({
    ...result["Total"],
    Turnover: parseFloat(result["Total"].Turnover.toFixed(2)),
    VAT: parseFloat(result["Total"].VAT.toFixed(2)),
  });  
// console.log(finalResult)
const handleExportChange = (exportType: any) => {
  setExportType(exportType[0])
}

const summarizeTaxes = (data: any) => {
  const summary: any[] = [];
  let totalTurnover = 0;
  let totalVAT = 0;

  // Summing VAT and Turnover amounts per tax name
  if (data !== null) {
    data.taxVAT.forEach((vatEntry: any) => {
      const correspondingTurnover = data.turnover.find((turnoverEntry: any) => turnoverEntry.VatName === vatEntry.VatName);
      if (correspondingTurnover) {
        const turnoverAmount = correspondingTurnover.Ammount;
        const vatAmount = vatEntry.Ammount;

        summary.push({
          VatName: vatEntry.VatName,
          Turnover: turnoverAmount,
          VAT: vatAmount
        });

        // Accumulate totals
        totalTurnover += turnoverAmount;
        totalVAT += vatAmount;
      }
    });

    // Add the totals row at the bottom
    summary.push({
      VatName: 'Total',
      Turnover: totalTurnover,
      VAT: totalVAT
    });

    return summary;
  } else {
    return [];
  }

};
const groupedOOT = groupData(outOfTurnover);

const transformedOOT = transformGroupedDetails(groupedOOT, 3, t);

const summary = summarizeTaxes(allTaxes && allTaxes);
const options = getTableOptions(finalResult, vatTotalsTH);
const options4 = getTableOptions(summary, vatTotalsTH);
const options5 = getTableOptions(transformedOOT, productsTH);
const handleFilters = (filters: any) => {
  setFilters(filters)
}

const { getPaymentTypeName } = usePaymentTypeMap();

  return (
    <Wrapper onClick={() => {}} classes={`w-[95%] mx-auto pt-2 md:pt-4 pb-16 min-h-screen`}>
        <PercentageReportFilters sendFiltersToParent={handleFilters} exportType={exportType} fileUrl={fileUrl} getDataBe={getDataBe} handleExportChange={handleExportChange}/>  
       
        <div className="relative pt-[27rem] md:pt-[22rem]" style={{ maxHeight: "95dvh", overflowY: "auto" }}>

        {percentagesData.length > 0 ? <>

    {products.length > 0 ? (
      <div 
      onClick={() => toggleSection("sales")} 
      className="flex justify-between items-center px-2 md:px-4 rounded-lg border border-gray-200 w-full mb-0 relative my-2 py-2 md:py-4 bg-white shadow-custom"
      >
          {t("percentageReport.sales")} {openSections["sales"] ? (<ArrowUpIcon color="#6941C6"/>) : (<ArrowDownIcon color="#6941C6"/>)} 
        </div>
      ) : (<></>)}
      {products.length > 0 && openSections["sales"] ? <PercentageReportTable options={options3}/> : <></>}

      {summary.length > 0 ? (<div 
        onClick={() => toggleSection("allTaxes")} 
        className="flex justify-between items-center px-2 md:px-4 rounded-lg border border-gray-200 w-full relative mb-0 my-2 py-2 md:py-4 bg-white shadow-custom"
        >
          {t("percentageReport.vatTotals")} {openSections["allTaxes"] ? (<ArrowUpIcon color="#6941C6"/>) : (<ArrowDownIcon color="#6941C6"/>)} 
        </div>) : (<></>)}
      {summary.length > 0 && openSections["allTaxes"] ?  <PeriodReportTable options={options4}/> : <></>}
      
      {isTaxParentGroup === true && vatTotals.length > 0 ? (
        <div 
        onClick={() => toggleSection("vatTotals")} 
        className="flex justify-between items-center px-2 md:px-2 rounded-lg border border-gray-200 w-full relative mb-0 my-2 py-2 md:py-4 bg-white shadow-custom"
        >
          {t("percentageReport.parentVatTotals")} {openSections["vatTotals"] ? (<ArrowUpIcon color="#6941C6"/>) : (<ArrowDownIcon color="#6941C6"/>)} 
        </div>
      ) : (<></>)}
      {finalResult.length > 0 && openSections["vatTotals"] && isTaxParentGroup ? <PeriodReportTable options={options}  vatTotals={finalResult}/> : <></>}
      
      {paymentData.length > 0 ? (
        <div 
        onClick={() => toggleSection("payments")} 
        className="flex justify-between items-center mb-1 px-2 md:px-4 rounded-lg border border-gray-200 w-full relative my-2 py-2 md:py-4 bg-white shadow-custom"
        >
          {t("percentageReport.payments")} {openSections["payments"] ? (<ArrowUpIcon color="#6941C6"/>) : (<ArrowDownIcon color="#6941C6"/>)} 
        </div>
      ) : (<></>)}
      {paymentData.length > 0 && openSections["payments"] ? (<Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 shadow-lg">
        {paymentData.map((item: any) => {
          return(
            <ZReportRow key={uuidv4()} title={getPaymentTypeName(item.IdPaymentType)} value={item.TotalOrderGroupWithDiscount.toFixed(2)} color={item.IdPaymentType === 13 ? "bg-custom-bgOrange" : ""}/>
          )
        })}
      <ZReportRow key={uuidv4()} title={t("percentageReport.total")} value={totalPaymentsAmount.toFixed(2)} color="bg-custom-bgPurple2" classes="py-2 mt-2"/>
          
            </Wrapper> ) : (<></>)}

      {discounts.length > 0 ? (
        <div 
        onClick={() => toggleSection("discounts")} 
        className="flex justify-between items-center px-2 md:px-4 mb-0 rounded-lg border border-gray-200 w-full relative my-2 py-2 md:py-4 bg-white shadow-custom"
        >
          {t("percentageReport.discounts")} {openSections["discounts"] ? (<ArrowUpIcon color="#6941C6"/>) : (<ArrowDownIcon color="#6941C6"/>)} 
        </div>
      ) : (<></>)}
      {discounts.length > 0 && openSections["discounts"] ? (<PeriodReportTable options={options2} tableHeads={discountsTH} discountsPercentageReport={discounts}/>) : (<></>)}

      {tips.length > 0 ? (
        <div 
        onClick={() => toggleSection("tips")} 
        className="flex justify-between items-center mb-1 px-2 md:px-4 rounded-lg border border-gray-200 w-full relative my-2 py-2 md:py-4 bg-white shadow-custom"
        >
                  {t("percentageReport.tips")} {openSections["tips"] ? (<ArrowUpIcon color="#6941C6"/>) : (<ArrowDownIcon color="#6941C6"/>)} 
                </div>
      ) : (<></>)}
      {tips.length > 0 && openSections["tips"] ? (<Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 shadow-lg"> 
      <ZReportRow key={uuidv4()} title={tips.length} value={tipsAmountTotal} />
      </Wrapper>) : (<></>)}

      {serviceFee.length > 0 ? (
        <div 
        onClick={() => toggleSection("serviceFee")} 
        className="flex justify-between items-center px-2 md:px-4 rounded-lg border border-gray-200 w-full relative my-2 py-2 md:py-4 bg-white shadow-custom"
        >
                {t("percentageReport.serviceFee")} {openSections["serviceFee"] ? (<ArrowUpIcon color="#6941C6"/>) : (<ArrowDownIcon color="#6941C6"/>)} 
              </div>
      ) : (<></>)}
      {serviceFee.length > 0 && openSections["serviceFee"] ? (<Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 shadow-lg"> 
      <ZReportRow key={uuidv4()} title={serviceFee.length} value={serviceFeeAmountTotal} />
      </Wrapper>) : (<></>)}

      {outOfTurnover.length > 0 ? (<div onClick={() => toggleSection("outOfTurnover")} className="flex justify-between items-center px-2 md:px-4 rounded-lg border border-custom-bgOrange w-full relative my-2 mb-0 py-2 md:py-4 mt-4 bg-custom-bgOrange text-white shadow-custom3">{t("soldProducts.outOfTurnover")} {openSections["outOfTurnover"] ? (<ArrowUpIcon color="white"/>) : (<ArrowDownIcon color="white"/>)} </div>) : (<></>)}
      {outOfTurnover.length > 0 && openSections["outOfTurnover"] ? <SoldProductsTable options={options5}  details={outOfTurnover} borderColor="border-custom-bgOrange" totalColor="bg-custom-bgOrange" /> : <></>}  
      </> : <div className="text-gray-500 pt-12 h-screen">{t("errorMessages.noDataToBeShownForThisPeriod")}</div>}
   
      </div>
      <ScrollToTopButton />
    </Wrapper>
  );
};

export default PercentageReport;
