import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<any>();

export const getTableHeaders = (t: any) => {

  return [
    columnHelper.accessor('VatName', {
        header: t("vatReport.vatName"),
        cell: info => info.getValue()
      }),
      columnHelper.accessor('ExclVAT', {
        header: t("vatReport.excludedVat"),
        cell: info => info.getValue()
      }),
      columnHelper.accessor('VAT', {
        header: t("vatReport.vat"),
        cell: info => info.getValue()
      }),
    ];
};