import React, { useEffect, useState } from "react";
import FilterIcon from "../../icons/FilterIcon";
import Dropdown from "../dropdown/Dropdown";
import { useTranslation } from "react-i18next";
import useIsMobileDevice from "../../../hooks/useIsMobileDevice";

interface FilterPopoverProps{
    options: any,
    onFilterChange: (filter: any) => void,
    selected?: any,
    isTableFilter?: boolean
}

const FilterPopover: React.FC<FilterPopoverProps> = ({options, onFilterChange, selected, isTableFilter = false}) => {
    const { t } = useTranslation();
    const isMobile = useIsMobileDevice();
    const [filter, setFilter] = useState<any[]>([])
    const [selectedFilters, setSelectedFilters] = useState(localStorage.getItem("receiptFilter"))
    const parsedSelectedFilters = selectedFilters ? JSON.parse(selectedFilters) : [0]



    const handleFilterChange = (item: any) => {
        setFilter(item);
    };
    useEffect(() => {
        onFilterChange(filter)
    }, [filter])

    return(
    <div className="flex my-2 items-start gap-2 w-full">
        {/* <span className="mt-2">

        <FilterIcon w={isMobile ? "16px" : ""} h={isMobile ? "16px" : ""}/>
        </span> */}
        <div className="w-full flex items-center">
        <Dropdown classes="bg-white text-xs md:text-base" options={options} onChange={handleFilterChange} title={!isTableFilter ? options[0].label :  isMobile ? t("receiptReport.tables") : t("receiptReport.tables")} isMultiSelect={true} isReceiptTable={true} defaultValue={parsedSelectedFilters} selected={selected} isTableFilter={isTableFilter}/>
        </div>
    </div>)
}

export default FilterPopover;  