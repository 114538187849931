import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Wrapper from "../common/wrapper/Wrapper";
import { v4 as uuidv4 } from 'uuid';
import { SoldProductsTableProps } from "./SoldProductsTable.type";
import { createColumnHelper, flexRender, getCoreRowModel, getGroupedRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';


const SoldProductsTable: React.FC<SoldProductsTableProps> = ({ options, details, tableHeads, borderColor, totalColor }) => {
  const { t } = useTranslation();

  const groupData = (data: any) => {
    const groupedData = data.reduce((acc: any, item: any) => {
      const mainCategory = item.MainCategory;
      const subCategory = item.SubCategory;

      if (!acc[mainCategory]) {
        acc[mainCategory] = {
          items: [],
          totalQuantity: 0,
          totalSubTotal: 0,
          totalTotal: 0,
          totalTaxExcludedWithDiscount: 0,
        };
      }

      if (!acc[mainCategory][subCategory]) {
        acc[mainCategory][subCategory] = {
          items: [],
          totalQuantity: 0,
          totalSubTotal: 0,
          totalTotal: 0,
          totalTaxExcludedWithDiscount: 0,
        };
      }

      acc[mainCategory].items.push(item);
      acc[mainCategory].totalQuantity += item.Quantity;
      acc[mainCategory].totalSubTotal += item.SubTotal;
      acc[mainCategory].totalTotal += item.Total;
      acc[mainCategory].totalTaxExcludedWithDiscount += item.TaxExcludedWithDiscount;

      acc[mainCategory][subCategory].items.push(item);
      acc[mainCategory][subCategory].totalQuantity += item.Quantity;
      acc[mainCategory][subCategory].totalSubTotal += item.SubTotal;
      acc[mainCategory][subCategory].totalTotal += item.Total;
      acc[mainCategory][subCategory].totalTaxExcludedWithDiscount += item.TaxExcludedWithDiscount;

      return acc;
    }, {});

    return groupedData;
  };

  const groupedDetails = groupData(details);

  // const overallTotals = Object.keys(groupedDetails).reduce((acc: any, mainCategory) => {
  //   acc.totalQuantity += groupedDetails[mainCategory].totalQuantity;
  //   acc.totalSubTotal += groupedDetails[mainCategory].totalSubTotal;
  //   acc.totalTotal += groupedDetails[mainCategory].totalTotal;
  //   acc.totalTaxExcludedWithDiscount += groupedDetails[mainCategory].totalTaxExcludedWithDiscount;
  //   return acc;
  // }, {
  //   totalQuantity: 0,
  //   totalSubTotal: 0,
  //   totalTotal: 0,
  //   totalTaxExcludedWithDiscount: 0,
  // });

  const transformGroupedDetails = (groupedDetails: any) => {
    const transformedData: any[] = [];
  
    Object.keys(groupedDetails).forEach((mainCategory) => {
      const mainCategoryData = groupedDetails[mainCategory];
      
      Object.keys(mainCategoryData).forEach((subCategory) => {
        if (subCategory !== "items" && !subCategory.startsWith("total")) {
          mainCategoryData[subCategory].items.forEach((item: any) => {
            transformedData.push({
              ...item,
              MainCategory: mainCategory,
              SubCategory: subCategory,
              isSubTotal: false,
              isMainTotal: false,
            });
          });
  
          // Add Subcategory total
          transformedData.push({
            Year: null,
            Date: null,
            Month: null,
            MonthName: null,
            Time: null,
            ProductName: `${subCategory} ${t("soldProducts.total")}`,
            Quantity: mainCategoryData[subCategory].totalQuantity,
            SubTotal: mainCategoryData[subCategory].totalSubTotal,
            TaxExcludedWithDiscount: mainCategoryData[subCategory].totalTaxExcludedWithDiscount,
            Total: mainCategoryData[subCategory].totalTotal,
            MainCategory: mainCategory,
            SubCategory: subCategory,
            isSubTotal: true,
            isMainTotal: false,
          });
        }
      });
  
      // Add MainCategory total
      transformedData.push({
        Year: null,
        Date: null,
        Month: null,
        MonthName: null,
        Time: null,
        ProductName: `${mainCategory} ${t("soldProducts.total")}`,
        Quantity: mainCategoryData.totalQuantity,
        SubTotal: mainCategoryData.totalSubTotal,
        TaxExcludedWithDiscount: mainCategoryData.totalTaxExcludedWithDiscount,
        Total: mainCategoryData.totalTotal,
        MainCategory: mainCategory,
        SubCategory: null,
        isSubTotal: false,
        isMainTotal: true,
      });
    });
  
    return transformedData;
  };

  const transformedDetails = transformGroupedDetails(groupedDetails);

// Now you can use transformedDetails wherever you need, e.g., rendering in a table.


  const [isMobile, setIsMobile] = useState<boolean>();

useEffect(() => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobileDevice = /iphone|ipad|ipod|android/.test(userAgent);
  const isSmallScreen = window.innerWidth <= 768;

  setIsMobile(isMobileDevice && isSmallScreen);
}, []);

const columnHelper = createColumnHelper<any>()
const productsTH = [
  columnHelper.accessor('ProductName', {
    header: t("soldProducts.product"),
  }),
  columnHelper.accessor('Quantity', {
    header: t("soldProducts.amt"),
  }),
  columnHelper.accessor('SubTotal', {
    header: t("soldProducts.taxExcl"),
    cell: (info) => info.getValue().toFixed(2),
  }),
  columnHelper.accessor('TaxExcludedWithDiscount', {
    header: t("soldProducts.taxIncl"),
    cell: (info) => info.getValue().toFixed(2),
  }),
  columnHelper.accessor('Total', {
    header: t("soldProducts.taxInclInclBtw"),
    cell: (info) => info.getValue().toFixed(2),
  }),
];


// const options2 = {
// data: transformedDetails,
// columns: productsTH,
// getCoreRowModel: getCoreRowModel(),
// getPaginationRowModel: getPaginationRowModel(),
// initialState: {
//   pagination: {
//     pageIndex: 0, //custom initial page index
//     pageSize: 10, //custom default page size
//   },
// },
// }

  const table = useReactTable({
    ...options,
    getRowModel: getCoreRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    meta: {
      grouping: true,
    },
  });

  return (
    <Wrapper onClick={() => {}} classes="w-full">

<div className={`rounded-lg shadow-custom4 mt-1`}>


<table className="overflow-hidden w-full text-left text-xs md:text-base bg-white rounded-[10px]">
        <thead className="border-b border-custom-bgLightPurple rounded-[10px] bg-custom-tableHeadBg" style={{borderRadius: "10px !important"}}>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id} className="">
              {headerGroup.headers.map(header => (
                <th key={header.id} className=" font-normal py-2 pl-4 border-b border-custom-bgLightPurple md:border-b-0">
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())
                  }
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className=''>
  {table.getRowModel().rows.map((row, index) => {
    // Get the specific row's data to check the condition
    const data: any = row.original;
    const isSubTotal = data.isSubTotal;
    const isMainTotal = data.isMainTotal;
    const isGrandTotal = data.isGrandTotal;
    const isPromotion = data.IsPromotion;
    const isWithDiscount = data.IsWithDiscount;
    const isWithPromotionAndDiscount = data.IsPromotion == true && data.IsWithDiscount == true;
    return (
      <tr
      className={`${isSubTotal ? "bg-custom-tableBgBlue" : isMainTotal ? "bg-custom-tableBgGreen" : isGrandTotal && totalColor ? totalColor : isGrandTotal ? "bg-custom-tableBgPurple" : ""}`}
        key={row.id}
        style={{ backgroundColor: index % 2 !== 0 ? "#F3F3F3" : "white" }}
      >
        {row.getVisibleCells().map(cell => (
          <td key={cell.id} className={`py-4 pl-4 ${isGrandTotal && cell.column.id === "ProductName" ? "text-left" : ""}`}>
            {cell.column.id === 'ProductName' && (
              <>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                {isWithDiscount && !isPromotion && (
                  <span className="ml-1 text-white rounded text-xxxs md:text-xxs" style={{ backgroundColor: "#EF5F5F", padding: "1px"}}>
                    {t("soldProducts.discount")}
                  </span>
                )}
                {isPromotion && !isWithDiscount && (
                  <span className="ml-1 text-white rounded text-xxxs md:text-xxs" style={{fontSize: "8px", backgroundColor: "#2E90FA", padding: "1px"}}>
                    {t("soldProducts.promotion")}
                  </span>
                )}
                {isWithPromotionAndDiscount && (
                  <span className="ml-1 text-white rounded text-xxxs md:text-xxs" style={{fontSize: "8px", backgroundColor: "#8130C0", padding: "1px"}}>
                    {t("soldProducts.discountAndPromotion")}
                  </span>
                )}
              </>
            )}
            {cell.column.id !== 'ProductName' && flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
      </tr>
    );
  })}
</tbody>

        {/* <tfoot>
          {table.getFooterGroups().map(footerGroup => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map(header => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.footer, header.getContext())
                  }
                </th>
              ))}
            </tr>
          ))}
        </tfoot> */}
      </table>
      </div>
      <div className={`text-[8px] md:text-xs flex justify-between items-center w-[97%] mx-auto mt-2`}>
  <span className="flex items-center gap-2">
    {t("periodReport.showing")}
    <select
      className="border rounded-lg px-2 py-2"
      value={table.getState().pagination.pageSize}
      onChange={e => {
        table.setPageSize(Number(e.target.value));
      }}
    >
      {[10, 20, 30, 40, 50].map(pageSize => (
        <option key={pageSize} value={pageSize}>
          {pageSize}
        </option>
      ))}
    </select>
    <p style={{ color: "#5A5A5A" }}>
      {t("periodReport.itemsPerPage")}
    </p>
  </span>

  <span>
    {(() => {
      const totalPages = table.getPageCount();
      const currentPage = table.getState().pagination.pageIndex;
      const maxButtons = isMobile ? 2 : 5;
      const halfMaxButtons = Math.floor(maxButtons / 2);

      let startPage = Math.max(0, currentPage - halfMaxButtons);
      let endPage = Math.min(totalPages - 1, currentPage + halfMaxButtons);

      if (endPage - startPage + 1 < maxButtons) {
        if (startPage === 0) {
          endPage = Math.min(maxButtons - 1, totalPages - 1);
        } else if (endPage >= totalPages - 2) {
          startPage = Math.max(0, totalPages - maxButtons);
        }
      }

      return (
        <>
          {startPage > 0 && (
            <button
              className="px-2 py-1 md:px-3 md:py-1.5 border rounded-lg mx-1"
              onClick={() => table.setPageIndex(0)}
            >
              1
            </button>
          )}
          {startPage > 1 && <span className="px-2 py-1 md:px-3 md:py-1.5">...</span>}
          {Array.from({ length: endPage - startPage + 1 }, (_, i) => (
            <button
              key={startPage + i}
              className={`px-2 py-1 md:px-3 md:py-1.5 border rounded-lg mx-1 ${currentPage === startPage + i ? 'border border-custom-bgPurple text-custom-bgPurple rounded-lg' : ''}`}
              onClick={() => table.setPageIndex(startPage + i)}
            >
              {startPage + i + 1}
            </button>
          ))}
          {endPage < totalPages - 3 && <span className="px-2 py-1 md:px-3 md:py-1.5">...</span>}
          {endPage < totalPages - 2 && (
            <>
              <button
                className="px-2 py-1 md:px-3 md:py-1.5 border rounded-lg mx-1"
                onClick={() => table.setPageIndex(totalPages - 2)}
              >
                {totalPages - 1}
              </button>
              <button
                className="px-2 py-1 md:px-3 md:py-1.5 border rounded-lg mx-1"
                onClick={() => table.setPageIndex(totalPages - 1)}
              >
                {totalPages}
              </button>
            </>
          )}
        </>
      );
    })()}
    <button
      className="p-2 hidden md:inline"
      onClick={() => table.nextPage()}
      disabled={!table.getCanNextPage()}
      style={{ color: "#5A5A5A" }}
    >
      {t("periodReport.next")}
    </button>
    <button
      className="p-2 hidden md:inline"
      onClick={() => table.lastPage()}
      disabled={!table.getCanPreviousPage()}
      style={{ color: "#5A5A5A" }}
    >
      {t("periodReport.end")}
    </button>
  </span>
</div>
    </Wrapper>
  );
};

export default SoldProductsTable;
